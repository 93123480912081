import * as singleSpa from '@90poe/single-spa';
import type { APP_KEYS } from '@90poe/oos-config';
import {
    APP_STATUS_LOADED,
    appsConfig,
    APP_STATUS_UNKNOWN,
    APP_STATUS_NOT_FOUND,
    APP_STATUS_NOT_ALLOWED,
    config,
    USER_EXTRA_TYPE_TRAVEL_AGENT,
    USER_EXTRA_TYPE_SUPPLIER,
} from '@90poe/oos-config';
import { Severity } from '@90poe/journal';
import type { Store, IUser } from '@90poe/shared-state';
import { cleanupLocalStorage as cleanupLocalStorageApollo } from '@90poe/shared-state';
import { cleanupLocalStorage as cleanupLocalStorageLD } from '@90poe/feature-flags';

import { logError } from '../../helpers/logger';
import {
    filterRestrictedApps,
    isActiveApp,
    isLayoutApp,
    isProductApp,
} from '../../helpers/appsUtils';

export const SINGLE_SPA_ROUTING_EVENTS = {
    BEFORE_ROUTING_EVENT: 'single-spa:before-routing-event',
    ROUTING_EVENT: 'single-spa:routing-event',
    APP_CHANGE: 'single-spa:app-change',
};
type Keys = keyof typeof SINGLE_SPA_ROUTING_EVENTS;
type SingleSpaRoutingEvent = (typeof SINGLE_SPA_ROUTING_EVENTS)[Keys];

export const ERROR_MESSAGES = {
    NO_DEFAULT_PATH:
        'Value `config.DEFAULT_PATH` is not set. Will not attempt to navigate to default path when user visits application root (`/`).',
    NO_TRAVEL_AGENT_DEFAULT_PATH:
        'Value `config.DEFAULT_TRAVEL_AGENT_PATH` is not set. Will not attempt to navigate to default travel agent path when user visits application root (`/`).',
    NO_SUPPLIER_DEFAULT_PATH:
        'Value `config.DEFAULT_SUPPLIER_PATH` is not set. Will not attempt to navigate to default supplier path when user visits application root (`/`).',
};

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const routingEventListeners: Map<SingleSpaRoutingEvent, (e: Event) => void> =
    new Map();

export function initRoutingEventListeners(
    user: IUser,
    sharedStore: Store,
): void {
    const handleBeforeRoutingEvent = (): void => {
        const beforeRoutingMountedAppsKeys: Array<APP_KEYS> =
            singleSpa.getMountedApps() as Array<APP_KEYS>;

        // the usual set of mounted apps is 'nav', 'header' and 1 product app
        const productAppKey = beforeRoutingMountedAppsKeys.find((key) =>
            isProductApp(appsConfig, key),
        );

        // singleSpa.checkActivityFunctions() returns an array of apps that should be active for the given location
        const afterRoutingMountedAppsKeys = singleSpa.checkActivityFunctions(
            window.location,
        );

        // If it's initial load (no productAppKey), or product app will be unmounted
        // (no productAppKey in list of apps mounted after routing) - reset not needed
        if (
            !productAppKey ||
            !afterRoutingMountedAppsKeys.includes(productAppKey)
        ) {
            sharedStore.customPageTitle
                .resetCustomPageTitle()
                .catch((reason: unknown) => {
                    logError({
                        uuid: 'c25daaf9-841d-47de-a400-513eb17eac7b',
                        message: 'Failed to clean custom page title',
                        level: Severity.Error,
                        data: { reason },
                    });
                });
        }

        if (window.location.pathname === '/') {
            handleDefaultRoute(user.userExtraType);
        }
    };

    const handleRoutingEvent = () => {
        const mountedAppKeys: Array<APP_KEYS> =
            singleSpa.getMountedApps() as Array<APP_KEYS>;

        // the usual set of mounted apps is 'nav', 'header' and one of the product apps
        // using mountedAppKeys automatically restricts the set to only regegistered (and therefore ALLOWED) apps
        const productAppKey = mountedAppKeys.find((key) =>
            isProductApp(appsConfig, key),
        );

        // layout apps are 'nav' and 'header'
        const isAnyLayoutAppMounted = mountedAppKeys.some((key) =>
            isLayoutApp(appsConfig, key),
        );

        let appStatus = APP_STATUS_UNKNOWN;

        if (productAppKey) {
            // if there is a product app mounted, we know for sure that it's an Allowed app,
            // because only the allowed apps are registered as single-spa apps
            appStatus = APP_STATUS_LOADED;
        } else {
            const restrictedApps = filterRestrictedApps(appsConfig, user);
            const accessingRestrictedApp = Object.entries(restrictedApps).find(
                ([appKey, appConfig]) => {
                    return (
                        appConfig &&
                        restrictedApps[appKey as APP_KEYS] &&
                        isActiveApp(appConfig.pathPrefix, window.location)
                    );
                },
            );

            if (accessingRestrictedApp) {
                appStatus = APP_STATUS_NOT_ALLOWED;
            }
        }

        if (isAnyLayoutAppMounted && appStatus === APP_STATUS_UNKNOWN) {
            appStatus = APP_STATUS_NOT_FOUND;
        }

        sharedStore.appStatus
            .setAppStatus(appStatus)
            .catch((reason: unknown) => {
                logError({
                    uuid: '8bb5215d-0a00-4d29-9a28-e98af5ff0969',
                    message: 'Failed to update app status',
                    level: Severity.Error,
                    data: { reason },
                });
            });
    };

    const handleAppChange = (): void => {
        setTimeout(() => {
            // FIXME: it will cleanup the local storage LD entries with keys > 2000 characters
            // https://ninetypercent.atlassian.net/browse/PFE-1700
            // the timout ensures that the cleanup happens after the MFE's code is loaded and executed
            // It should stay here untill all MFE's are update to @90poe/feature-flags v3.2.0 in production.
            cleanupLocalStorageLD();

            // FIXME: it will cleanup the local storage from apollo cache persistence (ROOT cache key)
            // After Shell with this code will be deployed to all environments, wait a few weeks and remove this line
            // https://ninetypercent.atlassian.net/browse/PFE-2084
            cleanupLocalStorageApollo();
        }, 5000);
    };

    /**
     * TL;DR: single-spa:before-routing-event = single-spa is about to mount/unmount applications
     *
     * A single-spa:before-routing-event event is fired before every routing event occurs,
     *  which is after each hashchange, popstate, or triggerAppChange, even if no changes to registered applications were necessary.
     * https://single-spa.js.org/docs/api/#before-routing-event
     */
    window.addEventListener(
        SINGLE_SPA_ROUTING_EVENTS.BEFORE_ROUTING_EVENT,
        handleBeforeRoutingEvent,
    );
    routingEventListeners.set(
        SINGLE_SPA_ROUTING_EVENTS.BEFORE_ROUTING_EVENT,
        handleBeforeRoutingEvent,
    );

    /**
     * TL;DR: single-spa:routing-event = single-spa finished mounting/unmounting applications
     *
     * A single-spa:routing-event event is fired every time that a routing event has occurred,
     *  which is after each hashchange, popstate, or triggerAppChange, even if no changes to registered applications were necessary;
     *  and after single-spa verified that all apps were correctly loaded, bootstrapped, mounted, and unmounted.
     * https://single-spa.js.org/docs/api/#routing-event
     */
    // check app availability/presence here and pass the status to the shared state
    window.addEventListener(
        SINGLE_SPA_ROUTING_EVENTS.ROUTING_EVENT,
        handleRoutingEvent,
    );
    routingEventListeners.set(
        SINGLE_SPA_ROUTING_EVENTS.ROUTING_EVENT,
        handleRoutingEvent,
    );

    /**
     * TL;DR: single-spa:app-change = single-spa actually loaded, bootstrapped, mounted or unmouted one or more apps.
     *
     * A single-spa:app-change event is fired every time that one or more apps were loaded, bootstrapped, mounted, unmounted, or unloaded.
     * It is similar to single-spa:routing-event except that it will not fire unless one or more apps
     * were actually loaded, bootstrapped, mounted, or unmounted.
     * A hashchange, popstate, or triggerAppChange that does not result in one of those changes will not cause the event to be fired.
     * https://single-spa.js.org/docs/api/#app-change-event
     */
    window.addEventListener(
        SINGLE_SPA_ROUTING_EVENTS.APP_CHANGE,
        handleAppChange,
    );
    routingEventListeners.set(
        SINGLE_SPA_ROUTING_EVENTS.APP_CHANGE,
        handleAppChange,
    );
}

export function removeRoutingEventsListeners(): void {
    routingEventListeners.forEach((eventListener, eventName) => {
        window.removeEventListener(eventName, eventListener);
    });
}

function handleDefaultRoute(userExtraType: string | undefined) {
    // If config.DEFAULT_PATH is not set, we don't want to proceed any other logic
    // this means that there is bigger problem in the platform.
    if (!config?.DEFAULT_PATH) {
        logError({
            uuid: '2722bb29-fd20-4563-b312-8c0c8a3ce95e',
            message: ERROR_MESSAGES.NO_DEFAULT_PATH,
            level: Severity.Warning,
            data: { config },
        });
        return;
    }

    // redirection below comes into effect only if the external user is logged in and tries opening the app with the root path ("/");
    // redirection to the expected MFE from the magic link happens in the auth package based on the state stored in URL hash;
    switch (userExtraType) {
        case USER_EXTRA_TYPE_TRAVEL_AGENT:
            if (!config?.DEFAULT_TRAVEL_AGENT_PATH) {
                logError({
                    uuid: 'c62579d4-bd83-4d27-b3ca-acdd80165790',
                    message: ERROR_MESSAGES.NO_TRAVEL_AGENT_DEFAULT_PATH,
                    level: Severity.Warning,
                    data: { config },
                });
                return;
            }
            singleSpa.navigateToUrl(config.DEFAULT_TRAVEL_AGENT_PATH);
            break;
        case USER_EXTRA_TYPE_SUPPLIER:
            if (!config?.DEFAULT_SUPPLIER_PATH) {
                logError({
                    uuid: '371348aa-8abf-4cc4-b78a-861ff3b8f0d6',
                    message: ERROR_MESSAGES.NO_SUPPLIER_DEFAULT_PATH,
                    level: Severity.Warning,
                    data: { config },
                });
                return;
            }
            singleSpa.navigateToUrl(config.DEFAULT_SUPPLIER_PATH);
            break;
        default:
            singleSpa.navigateToUrl(config.DEFAULT_PATH);
    }
}
